import { Main } from '../components/main';
import { CustomHeader } from '../components/header';

function App() {
  return (
    <div className="app">
      <CustomHeader />
      <Main />
    </div>
  );
}

export default App;
