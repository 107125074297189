import React, { useContext, useState } from "react";
import { Flex, Grid } from "@mantine/core";
import * as icons from "@react-icons/all-files";
import { SiHtml5 } from "react-icons/si";
import { SiCss3 } from "react-icons/si";
import { SiJavascript } from "react-icons/si";
import { SiTypescript } from "react-icons/si";
import { SiNodedotjs } from "react-icons/si";
import { SiJquery } from "react-icons/si";
import { RiReactjsFill } from "react-icons/ri";
import { SiNestjs } from "react-icons/si";
import { SiRedux } from "react-icons/si";

import { SiGraphql } from "react-icons/si";
import { SiVuetify } from "react-icons/si";
import { SiPython } from "react-icons/si";
import { SiDjango } from "react-icons/si";
import { SiLua } from "react-icons/si";
import { FaJava } from "react-icons/fa";
import { SiKotlin } from "react-icons/si";
import { SiPostgresql } from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { SiGithub } from "react-icons/si";
import { SiPowershell } from "react-icons/si";
import { FaExpandArrowsAlt, FaFistRaised, FaSlideshare } from "react-icons/fa";
import { GiGears } from "react-icons/gi";
import { SlSpeedometer } from "react-icons/sl";
import { ImEye } from "react-icons/im";
import { FaRegLightbulb } from "react-icons/fa";

import { BadgeFilterContext } from "../";
import skills from '../../../assets/data/skills.json'

const getIconskill = (type, name) => {
  if (type === "hard") {
    switch (name) {
      case "HTML":
        return { icon: SiHtml5, color: 'e45c23' };
      case "CSS":
        return { icon: SiCss3, color: '26a0d4' };
      case "Javascript":
        return { icon: SiJavascript, color: 'ead41c' };
      case "Typescript":
        return { icon: SiTypescript, color: '2f72bc' };
      case "NodeJS":
        return { icon: SiNodedotjs, color: '4f9640' };
      case "JQuery":
        return { icon: SiJquery, color: '1048a5' };
      case "React":
        return { icon: RiReactjsFill, color: '61dafb' };
      case "NestJS":
        return { icon: SiNestjs, color: 'd61f4b' };
      case "Redux":
        return { icon: SiRedux, color: '7046b2' };
      case "GraphQL":
        return { icon: SiGraphql, color: 'e10098' };
      case "Vue":
      case "Vuetify":
        return { icon: SiVuetify, color: '1762b6' };
      case "Python":
        return { icon: SiPython, color: 'ecc945' };
      case "Django":
        return { icon: SiDjango, color: '0e3b2c' };
      case "Lua":
        return { icon: SiLua, color: '000079' };
      case "Java":
        return { icon: FaJava, color: 'e61f24' };
      case "Kotlin":
        return { icon: SiKotlin, color: '7b5bf2' };
      case "PHP":
      case "SQL":
        return { icon: SiPostgresql, color: '2f5c8b' };
      case "NoSQL":
        return { icon: SiMongodb, color: '81b75e' };
      case "Git":
        return { icon: SiGithub, color: 'e44d31' };
      case "Shell":
        return { icon: SiPowershell, color: '1e75c2' };
      default:
        return { icon: SiHtml5, color: 'e45c23' };
    }
  } else if (type === "soft") {
    switch (name) {
      case "Polyvalence":
        return { icon: FaExpandArrowsAlt } ;
      case "Leadership":
        return { icon: FaFistRaised } ;
      case "Autonomie":
        return { icon: GiGears } ;
      case "Vivacité":
        return { icon: SlSpeedometer } ;
      case "Sociabilité":
        return { icon: FaSlideshare } ;
      case "Acuité":
        return { icon: ImEye } ;
      case "Ingéniosité":
        console.log('là')
        return { icon: FaRegLightbulb } ;
      default:
        return { icon: FaExpandArrowsAlt } ;
    }
  }
};

function Badge({ type, skill }) {

  const [onHover, setOnHover] = useState(false);
  const { currentFilter, toggleFilter } = useContext(BadgeFilterContext);

  const { icon, color } = getIconskill(type, skill.name);  
  if (!icon) return null;


  return (
    <div>
      {icon({
          key: skill.name,
          color: `#${!color ? '3798cc' : color}${(currentFilter !== null && currentFilter === skill.name) || (onHover) ? 'ff' : '80'}`, 
          size: 48,
          title: skill.name,
          style: { cursor: 'pointer' },
          onMouseEnter: () => setOnHover(true),
          onMouseLeave: () => setOnHover(false),
          onClick: () => toggleFilter(skill.name)
      })}
    </div>
  );
}

export function Skills({ type }) {
  return (
    <div id={`skills-${type}`}>
      <h3>{type} skills</h3>
      <div>
        {skills[type].map((skill, index) => (
          <Badge key={`badge-skill-${index}`} type={type} skill={skill} />
        ))}
      </div>
    </div>
  );
}
